<template>
  <div class="about">
    <div class="titles azjgs">
      <input-list @setData="getData" iptType="1"></input-list>
      <div>
        监控地点:
        <el-input v-model="calias" placeholder="请输入监控地点" clearable></el-input>
      </div>
      <span>
        <el-button v-if="$has('viewMgrCamera')" type="success" size="mini" icon="el-icon-search" @click="searchs">查 询</el-button>
        <el-button v-if="$has('viewMgrCamera')" size="mini" icon="el-icon-refresh-right" @click="reserts" type="info">重 置</el-button>
        <el-button v-if="$has('addMgrCamera')" type="warning" size="mini" icon="el-icon-plus" @click="openDialog(0, 0)">新 增</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="areaName" label="区域名称" width="80"></el-table-column>
        <el-table-column prop="organizationName" label="机构名称"></el-table-column>
        <el-table-column prop="alias" label="监控地点"></el-table-column>
        <el-table-column prop="location" label="直播地址" width="300">
          <template slot-scope="scope">
            <video :id="'myPlayer_' + scope.row.id" autoplay :src="scope.row.location" controls playsInline muted webkit-playsinline></video>
          </template>
        </el-table-column>
        <el-table-column prop="label" label="监控序列号"></el-table-column>
        <el-table-column prop="channel" label="视频通道"></el-table-column>
        <el-table-column prop="recordDate" label="安装时间" width="100"></el-table-column>
        <el-table-column prop="createDt" label="添加时间" width="100"></el-table-column>
        <el-table-column prop="updateDt" label="更新时间" width="100"></el-table-column>
        <el-table-column prop="note" label="备注"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button v-if="$has('editMgrCamera')" type="primary" @click="openDialog(1, row)" size="mini" icon="el-icon-edit-outline">编 辑</el-button>
            <el-button v-if="$has('delMgrCamera')" type="danger" size="mini" @click="deletes(row.id)" icon="el-icon-close">删 除</el-button>
            <el-button v-if="$has('viewHistory')" type="warning" size="mini" @click="openDialog(2, row)" icon="el-icon-refresh">回 放</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 50, 100, 200, 500]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
      <!-- 新增、编辑模态框 -->
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :modal="true" :close-on-click-modal="false" @close="cancles" v-dialogDrag center>
        <div class="dialogsC">
          <div class="newDialog" v-if="!isLook">
            <input-list v-if="!isUseFlag" @setData="getData" iptType="2"></input-list>
            <div v-if="isUseFlag">
              <span>区域名称:</span>
              <el-input v-model="lgxqy" clearable :disabled="isUseFlag"></el-input>
            </div>
            <div v-if="isUseFlag">
              <span>机构名称:</span>
              <el-input v-model="jcdName" clearable :disabled="isUseFlag"></el-input>
            </div>
            <div>
              <span>监控地点:</span>
              <el-input v-model="paramsObj.alias" placeholder="请输入监控地点" clearable></el-input>
            </div>
            <div>
              <span>视频通道:</span>
              <el-input type="number" v-model="paramsObj.channel" placeholder="请输入视频通道" clearable></el-input>
            </div>
            <div>
              <span>监控序列号:</span>
              <el-input v-model="paramsObj.label" placeholder="请输入监控序列号" clearable></el-input>
            </div>
            <div>
              <span>直播地址:</span>
              <el-input v-model="paramsObj.location" placeholder="请输入直播地址" clearable></el-input>
            </div>
            <div>
              <span>安装日期:</span>
              <el-date-picker v-model="paramsObj.recordDate" :clearable="false" :editable="false" type="date" size="small" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div>
              <span>备注:</span>
              <el-input v-model="paramsObj.note" placeholder="请输入备注" clearable></el-input>
            </div>
          </div>
          <div class="newDialog" v-if="isLook" style="align-items: center">
            <div>
              <span>监控地点名称:</span>
              <el-input v-model="mlocation" disabled clearable></el-input>
            </div>
            <div>
              <span>回放开始时间:</span>
              <el-date-picker v-model="bstart" :clearable="false" :editable="false" type="datetime" value-format="yyyyMMddHHmmss" placeholder="选择开始时间"> </el-date-picker>
            </div>
            <div>
              <span>回放结束时间:</span>
              <el-date-picker v-model="bend" :clearable="false" :editable="false" type="datetime" value-format="yyyyMMddHHmmss" placeholder="选择结束时间"> </el-date-picker>
            </div>
            <div class="videos" v-if="bvideo" id="myPlayer1"></div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="!isLook">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="primary" @click="confirmClk">确 定</el-button>
        </span>
        <span slot="footer" class="dialog-footer" v-if="isLook">
          <el-button @click="cancles">取 消</el-button>
          <el-button type="warning" @click="clears">清 空</el-button>
          <el-button type="primary" @click="backs">播 放</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { cameraQuery, cameraSave, cameraUpdate, cameraDelete, cameraGetDetail } from '@/util/http'
import inputList from '@/components/inputList/inputList.vue'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { inputList, ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //数据
      cgxqy: '', //区域名称
      cjgmc: '', //机构名称
      calias: '', //监控地点
      mlocation: '', // 回放监控地点
      backCenterDialogVisible: false, // 控制回放模态框
      bstart: '', // 回放开始时间
      bend: '', // 回放结束时间
      bvideo: false, // 回放盒子
      baccessToken: '', // 回放的token
      bhref: '', // 回放的href
      players: [],
      videotapePlayer: undefined,
      user: '',
      paramsObj: {}, //新增编辑对象
      dialogVisible: false, //模态框显影
      dialogTitle: '', //模态框标题
      isUseFlag: false, //区域,机构,状态,显影
      dialogType: '', //新增编辑标记
      isLook: false, //查看回放模态框
      ids: [], //视频id数组
      id: '', //回放id
    }
  },
  methods: {
    // 拿值区域和机构
    getData(msg, iptType) {
      if (iptType == 1) {
        this.cgxqy = msg[0]
        this.cjgmc = msg[1]
      } else {
        this.paramsObj.areaId = msg[0]
        this.paramsObj.organizationId = msg[1]
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    searchs(currpage, pagesize) {
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        organizationId: this.cjgmc,
        alias: this.calias,
        areaId: this.cgxqy,
      }
      cameraQuery(params).then((data) => {
        if (data) {
          this.ids = [] // 置空ids中的内容
          let result = data.result
          this.tableData = result.records
          this.totals = result.total
          for (let i = 0, l = this.tableData.length; i < l; i++) {
            this.ids.push(this.tableData[i].id)
          }
        }
      })
      // 延时渲染视频
      this.renderVedio()
    },
    renderVedio() {
      setTimeout(() => {
        // 初始化视频
        for (var j = 0; j < this.ids.length; j++) {
          let player = new EZUIKit.EZUIPlayer('myPlayer_' + this.ids[j])
          this.players.push(player)
        }
      }, 2000)
    },
    stopvideotape() {
      if (this.videotapePlayer != undefined) {
        this.videotapePlayer.stop()
      }
      this.bvideo = false
    },
    // 展开新增,编辑模态框
    openDialog(falg, row) {
      this.dialogType = falg
      if (falg == 0) {
        this.dialogTitle = '新增监控信息'
        this.isUseFlag = false
        this.isLook = false
      } else if (falg == 1) {
        this.paramsObj = Object.assign({}, row)
        this.lgxqy = row.areaName
        this.jcdName = row.organizationName
        this.dialogTitle = '编辑监控信息'
        this.isUseFlag = true
        this.isLook = false
      } else if (falg == 2) {
        this.isLook = true
        this.dialogTitle = '查看监控回放'
        this.mlocation = row.alias
        this.id = row.id
      }
      this.dialogVisible = true
    },
    // 确认
    confirmClk() {
      if (this.dialogType == 0) {
        this.paramsObj.organizationId = this.cjgmc
        this.paramsObj.href = 'ezopen://open.ys7.com/' + this.paramsObj.label + '/' + this.paramsObj.channel + '.hd.rec'
      }
      if (!this.paramsObj.areaId) {
        this.$message.error('区域名称不能为空')
        return
      }
      if (!this.paramsObj.organizationId) {
        this.$message.error('机构名称不能为空')
        return
      }
      if (!this.paramsObj.alias) {
        this.$message.error('监控地点不能为空')
        return
      }
      if (!this.paramsObj.channel) {
        this.$message.error('视频通道不能为空')
        return
      }
      if (!this.paramsObj.label) {
        this.$message.error('监控序列号不能为空')
        return
      }
      if (!this.paramsObj.location) {
        this.$message.error('直播地址不能为空')
        return
      }
      if (!this.paramsObj.recordDate) {
        this.$message.error('安装日期不能为空')
        return
      }
      var newParams = qs.stringify(this.paramsObj)
      if (this.dialogType == 0) {
        cameraSave(newParams).then((data) => {
          if (data) this.returnState(data)
        })
      } else if (this.dialogType == 1) {
        cameraUpdate(newParams).then((data) => {
          if (data) this.returnState(data)
        })
      }
    },
    // 删除
    deletes(id) {
      this.$confirm('是否删除该条数据?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          cameraDelete(qs.stringify({ id })).then((data) => {
            if (data) {
              let deleteAfterPage = Math.ceil((this.totals - 1) / this.pagesize)
              let currentPage = this.currpage > deleteAfterPage ? deleteAfterPage : this.currpage
              this.currpage = currentPage < 1 ? 1 : currentPage
              this.returnState(data)
            }
          })
        })
        .catch(() => {})
    },
    // 返回状态
    async returnState(data) {
      await this.$message.success(data.message)
      this.cancles()
      this.searchs(this.currpage, this.pagesize)
    },
    // 取消
    cancles() {
      this.dialogVisible = false
      this.isUseFlag = false
      this.paramsObj = {}
      this.$bus.$emit('clickResert', 2)
      this.clears()
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.$bus.$emit('clickResert', 1)
      this.cgxqy = this.user.areaId
      this.cjgmc = this.user.organizationId
      this.calias = ''
    },
    // 回放清空
    clears() {
      this.bstart = ''
      this.bend = ''
      this.stopvideotape()
    },
    // 回放
    backs() {
      this.bvideo = true
      if (this.bstart == '' || this.bend == '') {
        this.$message.error('开始时间或结束时间不能为空,请重新输入')
      } else if (this.bstart > this.bend) {
        this.$message.error('开始时间不能大于结束时间,请重新输入')
      } else {
        cameraGetDetail(qs.stringify({ id: this.id })).then((data) => {
          if (data) {
            setTimeout(() => {
              let videotape = new EZUIKit.EZUIPlayer({
                id: 'myPlayer1',
                autoplay: true,
                controls: true,
                url: this.bhref + '?begin=' + this.bstart + '&end=' + this.bend,
                accessToken: data.result.accessToken,
                decoderPath: '/ezuikit',
                width: 470,
                height: 300,
              })
              this.videotapePlayer = videotape
            }, 1000)
          }
        })
      }
    },
  },
  mounted() {
    this.user = this.$store.state.user
    this.cgxqy = this.user.areaId
    this.cjgmc = this.user.organizationId
    this.searchs(1, 10)
  },
  destroyed() {
    for (var j = 0; j < this.players.length; j++) {
      this.players[j].stop()
    }
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
// 回放盒子
.videos {
  width: 100%;
  video {
    width: 100%;
  }
}
.cell {
  video {
    width: 100%;
  }
  // 操作按钮
  button {
    margin: 5px 0;
  }
}
// 回放
/deep/.loading-item {
  display: none !important;
}
</style>
